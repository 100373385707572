<template>
  <div class="">
    <v-card style="height:89vh;">
      <div style="display:flex;">
        <v-navigation-drawer
          permanent
          style="width:400px"
        >
          <v-list>
            

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  APPLICATION TUTORIAL
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h6" style="line-height:1.2">
                Video List
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                Click to Play
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
          <v-list
            nav
            dense
            style="max-height: 74vh;"
            class="overflow-y-auto"
          >
          <v-list-item-group>
            
            
            <v-list-item link v-for="video in this.videos" :key="video.title" @click.stop="loadVideo(video)">
              <v-list-item-icon>
                <v-icon>{{video.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{video.title}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          </v-list>

          
        </v-navigation-drawer>
        <div style="display: flex; justify-content: center; padding: 2.5px 2.5px">
            
            <div class="video-container">
              <iframe v-if="this.activeVideo.link" style="border-radius:3px;" width="700" height="434" :src="activeVideo.link" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
      </div>
        
      
    </v-card>
    
  </div>
</template>

<script>
export default {
  name: 'Tutorial',
  data() {
    return {
      activeVideo: {
        title:'',
        icon:'',
        link:''
      },
      videos: [
        { 
          title:'Video 1',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 2',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 3',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        { 
          title:'Video 4',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 5',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 6',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        { 
          title:'Video 7',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 8',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 9',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 10',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 11',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 12',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 13',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        { 
          title:'Video 14',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        {
          title:'Video 15',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 16',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1"
        },
        { 
          title:'Video 17',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1"
        },
        {
          title:'Video 18',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/ULW9583helk?playlist=ULW9583helk&loop=1&loop=1"
        },
        {
          title:'Video 19',
          icon:'mdi-youtube',
          link: "https://youtube.com/embed/0w4MK9aHq98?playlist=0w4MK9aHq98&loop=1&loop=1"
        },
      ]
    }
  },
  created() {
  },
  methods: {
    loadVideo(video) {
      this.activeVideo = video;
    }
  }
}
</script>
<style lang="scss" scoped>
  .v-list-item--active {
    background-color: rgb(204, 204, 204);
  }
  .video-container {
    position: relative;
    padding-bottom: 49.5%; /* 16:9 */
    // height: 0;
    height:100%;
    width:100%;
    margin:0 auto;
  }
  .video-container iframe {
    position: absolute;
    // top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
